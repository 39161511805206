.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container .unityWrapper {
  background: #030303;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container .unityWrapper .loadingBar {
  background: #8c8c8c;
  width: 400px;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.container .unityWrapper .loadingBar .loadingBarFill {
  height: 6px;
  border-radius: 5px;
  margin: 2px;
  background: #ffffff;
  transition: 0.3s width ease-out;
}

.container .unityWrapper canvas {
  width: min(calc(100vh * (16/9)), 100vw);
  max-width: 100%;
  max-height: 100%;
  height: auto;
  aspect-ratio: 16/9;
  display: block;
}
